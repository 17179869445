import { postRequest } from "../default.service"

export const getDashboardData = (payload) => {
    return postRequest(`${process.env.REACT_APP_BASE_URL}/dashboard`, payload)
}

export const getUserDetails = (payload) => {
    return postRequest(`${process.env.REACT_APP_BASE_URL}/get-user-details`, payload)
}

export const userAddAccount = (payload) => {
    return postRequest(`${process.env.REACT_APP_BASE_URL}/user-add-account`, payload)
}