import { useState } from "react";
import {
  Button,
  Divider,
  fade,
  withStyles,
  FormControl,
  InputBase,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Switch,
  CircularProgress,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link, useHistory } from "react-router-dom";
import { login } from "../../services/authentication/authentication.service";
import { useDispatch, useSelector } from "react-redux";
import { setAuthDetails } from "../../redux/slices/authentication/authSlice";
import { getUserDetails } from "../../services/dashboard/dashboard.service";
import logo from "../../assets/images/logo.svg";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fff",
    border: "0px solid #BCBBBB",
    fontSize: 14,
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${fade("#4285f4", 0.25)} 0 0 0 0rem`,
      borderColor: "#4285f4",
    },
  },
}))(InputBase);

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    login(formData)
      .then((res) => res.json())
      .then(async (response) => {
        if (response.success) {
          setLoading(false);
          dispatch(
            setAuthDetails({
              userID: response.data.userID,
              hash: response.data.hash,
            })
          );

          history.push("/in/overview");
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (e) => {
    e.preventDefault()
  }

  const onChange = (e) => {
    const { email, password } = e.target;
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div style={{ padding: 20, marginTop: 70 }}>
      <div className="text-center">
        <img src={logo} />
      </div>
      <h4 className="text-center text-gray mt-12">Log In</h4>
      <p className="text-center text-gray fs-12 mt-4">
        Welcome back to Cashmark
      </p>
      <form onSubmit={onSubmit}>
        <div className="mt-4">
          <FormControl className="input" style={{ width: "100%" }}>
            <BootstrapInput
              defaultValue={formData.email}
              name="email"
              id="email"
              onChange={onChange}
              autoComplete="off"
              placeholder="Email"
            />
          </FormControl>
        </div>
        <div className="mt-4">
          <FormControl className="input" style={{ width: "100%" }}>
            <BootstrapInput
              defaultValue={formData.password}
              name="password"
              id="password"
              type={showPassword ? "text" : "password"}
              onChange={onChange}
              autoComplete="off"
              placeholder="Password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="mt-4">
          <Button
            className="text-white w-full text-capitalize bold bg-purple mt-4"
            type="submit"
          >
            {loading ? (
              <CircularProgress
                className="text-white"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              "Log In"
            )}
          </Button>
          <div className="text-gray fs-14 text-center mt-4">
            {" "}
            Don't have an account?{" "}
            <Link to="/signup" className="text-decoration-none">
              <span className="text-purple"> Create one</span>
            </Link>{" "}
          </div>
          <div className="fs-14 text-center mt-4">
            {" "}
            <Link
            style={{color: '#303C42'}}
              to="/forgot-password"
              className="text-decoration-none bold"
            >
              {" "}
              Forgot Password?
            </Link>{" "}
          </div>
        </div>
      </form>
    </div>
  );
}
