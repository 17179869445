
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
// import { Plugins } from '@capacitor/core';
import one from '../../assets/icons/1.svg';
import two from '../../assets/icons/2.svg';
import three from '../../assets/icons/3.svg';
import four from '../../assets/icons/4.svg';

// const { Storage, SplashScreen } = Plugins

const sendFeedback = () => {

}
function Feedback(props) {
    const [isSuccess, setSuccess] = useState(false)

  const giveFeedback = (emotion) => {
      const payload = {
        emotion,
        hash: "",
        bvn: props.bvn
    }

    sendFeedback(payload)
    .then(res => res.json())
    .then(response => {
        if (response.success){
            setSuccess(true)
        } else {
            setSuccess(false)
        }
    })
    .catch(e => console.error(e))
  }

  return (
    <div style={{background: '#ECECEC', padding: 20}}>
        {/* {isSuccess && <AlertDialog message="Thanks for the feedback!"/>} */}
    <div className="fs-13" style={{color: '#6C6767', textAlign: 'center', paddingTop: '1rem'}}>How are you liking Cashmark?</div>
    <div style={{textAlign: 'center', marginTop: '1rem'}}>
      <div className="review" onClick={() => giveFeedback('1')}><img src={one}/></div>
      <div className="review" onClick={() => giveFeedback('2')}><img src={two} /></div>
      <div className="review" onClick={() => giveFeedback('3')}><img src={three} /></div>
      <div className="review" onClick={() => giveFeedback('4')}><img src={four} /></div>
    </div>
  </div>
  );
}

export default Feedback;
