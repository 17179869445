import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { DateRange, Home, HomeOutlined, PersonOutline, Schedule } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setComponent } from '../../redux/slices/authentication/authSlice'

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    height: 60
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  }
}));

export default function BottomNavbar() {
  const classes = useStyles();
  const dispatch = useDispatch()
  const history = useHistory()

  const setComp = (comp) => {
    dispatch(setComponent(comp))
    history.push(`/in/${comp}`)
  }

  return (
      <AppBar position="fixed" color="primary" className={classes.appBar + " bg-white"}>
        <Grid container spacing={2} style={{paddingTop: 10}}>
          <Grid item sm={3} xs={3} lg={3} className="text-center">
            <div onClick={() => setComp('overview')}>
              <div ><HomeOutlined className="text-gray"/></div>
              <div className="fs-12 text-gray">Overview</div>
            </div>
          </Grid>
          <Grid item sm={3} xs={3} lg={3} className="text-center">
            <div onClick={() => setComp('budget')}>
              <div><Schedule className="text-gray"/></div>
              <div className="fs-12 text-gray">Budget</div>
            </div>
          </Grid>
          <Grid item sm={3} xs={3} lg={3} className="text-center">
            <div onClick={() => setComp('subscriptions')}>
              <div><DateRange className="text-gray"/></div>
              <div className="fs-12 text-gray">Subscriptions</div>
            </div>
          </Grid>
          <Grid item sm={3} xs={3} lg={3} className="text-center">
            <div onClick={() => setComp('profile')}>
              <div><PersonOutline className="text-gray" /></div>
              <div className="fs-12 text-gray">Profile</div>
            </div>
          </Grid>
        </Grid>
      </AppBar>
  );
}
