
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import BottomNavbar from '../components/dashboard/BottomNavbar';
import Navbar from '../components/dashboard/Navbar';
import Accounts from '../views/accounts/Accounts';
import Budgets from '../views/budgets/Budgets';
import CreateBudget from '../views/budgets/CreateBudget';
import Dashboard from '../views/dashboard/Dashboard';
import CreateCustomCategory from '../views/profile/CreateCustomCategory';
import EditCustomCategory from '../views/profile/EditCustomCategory';
import EditProfile from '../views/profile/EditProfile';
import ManageCustomCategories from '../views/profile/ManageCustomCategories';
import Profile from '../views/profile/Profile';
import UnlinkAccount from '../views/profile/UnlinkAccount';
import CancelSubscription from '../views/subscriptions/CancelSubscription';
import Subscriptions from '../views/subscriptions/Subscriptions';
import ListTransactions from '../views/transactions/ListTransactions';
const Route = require('react-router-dom').Route

function DashboardLayout(props) {
  const history = useHistory()
  // const currComponent = useSelector(state => state.auth.currComponent)
  // console.log('curr comp', currComponent)

  useEffect(() => {

  }, [])
  return (
    <div className="" style={{margin: 'auto'}}>
    <div>
      <Route path="/in/overview" exact strict component={Dashboard} />
      <Route path="/in/budget" exact strict component={Budgets} />
      <Route path="/in/create-budget" exact strict component={CreateBudget} />
      <Route path="/in/subscriptions" exact strict component={Subscriptions} />
      <Route path="/in/cancel-subscription" exact strict component={CancelSubscription} />
      <Route path="/in/profile" exact strict component={Profile} />
      <Route path="/in/edit-profile" exact strict component={EditProfile} />
      <Route path="/in/accounts" exact strict component={Accounts} />
      <Route path="/in/unlink-account" exact strict component={UnlinkAccount} />
      <Route path="/in/transactions" exact strict component={ListTransactions} />
      <Route path="/in/manage-custom-categories" exact strict component={ManageCustomCategories} />
      <Route path="/in/create-custom-category" exact strict component={CreateCustomCategory} />
      <Route path="/in/edit-custom-category" exact strict component={EditCustomCategory} />
        {/* <Route path="/in/generate" exact strict component={() => <Home customerData={props.customerData}/>} /> */}
      </div>
      <BottomNavbar/>
    </div>
  );
}

export default DashboardLayout;
