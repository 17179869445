import { postRequest } from "../default.service"

export const signup = (payload) => {
    return postRequest(`${process.env.REACT_APP_BASE_URL}/signup`, payload)
}

export const login = (payload) => {
    return postRequest(`${process.env.REACT_APP_BASE_URL}/login`, payload)
}

export const verifyOtp = (payload) => {
    return postRequest(`${process.env.REACT_APP_BASE_URL}/validate-otp`, payload)
}

export const verifyEmail = (payload) => {
    return postRequest(`${process.env.REACT_APP_BASE_URL}/user-forgot-password`, payload)
}

export const resetPassword = (payload) => {
    return postRequest(`${process.env.REACT_APP_BASE_URL}/user-forgot-passsword-next`, payload)
}
