import { Button, Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getBudgets } from "../../services/budgets/budgets.service";

const a = 20
const b = 80
export default function Budgets() {
    const { userID, hash } = useSelector(state => state.auth.auth)
    const [budgets, setBudgets] = useState([])
    const history = useHistory()

    const getUserBudgets = () => {
        const payload = {
            hash,
            userID: "TR-29149410"
        }
        getBudgets(payload)
        .then(res => res.json())
        .then(response => {
            if (response.success){
                setBudgets(response.data)
            }
        })
        .catch(e => console.error(e))
    }

    useEffect(() => {
        getUserBudgets()
    }, [])

  return (
    <div>
        <h1 style={{fontSize: 24, color: '#282828', padding: 20}}>Budgets</h1>
        {budgets.length ?
        <div>
        <div style={{padding: 20, paddingTop: 0}}>
            <div className="text-center text-purple bold" style={{fontSize: 13}}>
                YAY! You are doing great
            </div>
            <div style={{fontSize: 13, marginTop: 20}} className="bold text-gray text-center">
                You have  <span style={{fontSize: 18}} className="text-purple">₦130,000.00</span>  left to spend
            </div>
        </div>
        <Divider/>

        <div style={{padding: 20}}>
            <div className="text-purple bold fs-14">Budget Overview</div>
            <div className="text-purple fs-12" style={{marginTop: 5}}>From 1 may 2021 to 1 June 2021</div>
        </div>
        
        <div style={{padding: 20, paddingTop: 0}}>
            <div className="light-progress-bar w-full">
                <div className="progress-bar"></div>
            </div>
            <div className="mt-2">
            <div className="absolute">
                <div style={{color: '#B7AFAF'}} className="fs-11">Amount Spent</div>
                <div style={{color: 'red'}} className="bold fs-12">₦ 20,000</div>
            </div>
            <div style={{float: 'right'}}>
                <div style={{color: '#B7AFAF'}} className="fs-11">Total Budget</div>
                <div style={{color: 'red'}} className="bold fs-12 text-purple">₦ 150,000</div>
            </div>
            </div>
        </div>
        <Divider style={{marginTop: 30}}/>

        <div style={{padding: 20, marginBottom: 50}}>
            <div className="text-purple bold text-center">Your Budgets</div>
            <div style={{color: '#8E8A8A'}} className="fs-11 mt-2 text-center bold">Sort your transactions often to know when you are on budget</div>
            
            { budgets.length ?
                budgets.map((item, index) => (
            <div className="mt-4" style={{padding: 20, borderRadius: 8, border: '1px solid #662E50'}} key={index}>
            <div className="text-purple bold fs-14">{item.category}</div>
                <div style={{marginBottom: 30}} className="mt-2">
                    <div className="light-progress-bar w-full">
                        <div className="progress-bar" style={{width: `${Math.floor((item.amount_Spent/item.amount) * 100)}%`}}></div>
                    </div>
                    <div className="mt-2">
                    <div className="absolute">
                        <div style={{color: '#B7AFAF'}} className="fs-11">Amount Spent</div>
                        <div style={{color: 'red'}} className="bold fs-12">₦ {item.amount_Spent.toLocaleString()}</div>
                    </div>
                    <div style={{float: 'right'}}>
                        <div style={{color: '#B7AFAF'}} className="fs-11">Budget</div>
                        <div style={{color: 'red'}} className="bold fs-12 text-purple">₦ {item.amount.toLocaleString()}</div>
                    </div>
                    </div>
                </div>
            </div>
            ))
            : 
           null
            }
        </div>
        
        </div>
        :
        <div style={{padding: 20, marginTop: 50}}>
        <div className="bg-purple rounded" style={{padding: 20, marginTop: 10, borderRadius: 4}}>
      <div className="text-white fs-12">
        Create a Budget and track your expenses.<br/>
        We will monitor and let you know when you are about to exhaust your budget
      </div>
  </div>
  <Button className="mt-4 text-purple bg-white w-full" style={{border: '1px solid #662E50'}} onClick={() => history.push('/in/create-budget')}>CREATE MY BUDGET</Button>
    </div>
}
    </div>
  );
}
