import { useState } from "react";
import { Button, Divider, fade, withStyles, FormControl, InputBase, InputLabel, FormControlLabel, Checkbox, FormGroup, Switch, CircularProgress } from '@material-ui/core';
import { Link, useHistory } from "react-router-dom";
import { resetPassword } from '../../services/authentication/authentication.service'
import { useSelector } from "react-redux";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #BCBBBB',
    fontSize: 14,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      boxShadow: `${fade('#4285f4', 0.25)} 0 0 0 0.2rem`,
      borderColor: '#4285f4',
    },
  },
}))(InputBase);


export default function ResetPassword() {
  const email = useSelector(state => state.auth.email)
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    password: '',
    confirmpassword: ''
  })

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    console.log('password', formData)
    if (formData.password === formData.confirmpassword){
      console.log('password', formData)
      resetPassword({
        email,
        password: formData.password
      })
      .then(res => res.json())
      .then(response => {
        if (response.success){
          setLoading(false)
          history.push('/')
        } else {
          setLoading(false)
        }
      })
      .catch(e => {
        console.error(e)
        setLoading(false)
      })
    }
  }

  const onChange = (e) => {
    const { name, value } = e.target
    setFormData({...formData, [name]: value })
  }

  return (
    <div style={{padding: 20, marginTop: 100}}>
        <h4 className="text-center text-gray">Create New Password</h4>
        <form onSubmit={onSubmit}>
          <div className="mt-4">
            <FormControl className="" style={{width: '100%'}}>
              <BootstrapInput defaultValue={formData.password} name="password" id="password" type="password" onChange={onChange} autoComplete="off" placeholder="Password"/>
            </FormControl>
          </div>
          <div className="mt-4">
            <FormControl className="" style={{width: '100%'}}>
              <BootstrapInput defaultValue={formData.confirmpassword} name="confirmpassword" id="confirmpassword" type="password" onChange={onChange} autoComplete="off" placeholder="Verify Password"/>
            </FormControl>
          </div>
          <div className="mt-4">
            <Button className="text-white w-full text-capitalize bold bg-purple mt-4" type="submit">
              {loading ? <CircularProgress className="text-white" style={{width: 25, height: 25}}/> : 'Reset Password'}
            </Button>
            <div className="text-gray fs-14 text-center mt-4"> Back to <Link to="/" className="text-decoration-none"><span className="text-purple"> Log In</span></Link> </div>
          </div>
        </form>
    </div>
  );
}
