import { Avatar, Button, Divider } from "@material-ui/core";
import { ChevronRight, Edit, EditOutlined } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useState } from "react";
import { useHistory } from "react-router";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function Profile() {
  const [state, setState] = useState({
    checkedA: true,
    checkedB: true,
    checkedC: true,
  });
  const history = useHistory()

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

    return (
      <div style={{marginBottom: 70}}>
        <div className="" style={{padding: 20, textAlign: 'right'}}>
              <EditOutlined className="" style={{marginTop: 0, color: '#575252'}} onClick={() => history.push('/in/edit-profile')}/>
        </div>
        <div style={{width: '100%'}}>
          <Avatar  style={{margin: 'auto'}}/>
          <div className="fs-13 bold text-center mt-2">Great Douglas</div>
          <div className="fs-11 text-center mt-2" style={{color: '#6C6767'}}>greatdouglas@gmail.com</div>
      </div>
      <Divider style={{marginTop: 40}}/>
        <div style={{padding: 20}} onClick={() => history.push('/in/manage-custom-categories')}>
          <div className="absolute" style={{width: 25, height: 25, borderRadius: '50%', border: '1px solid #662E50'}}></div>
          <div className="fs-12 absolute" style={{marginLeft: 35, marginTop: 5}}>Manage Custom Categories</div>
          <div style={{textAlign: 'right'}}><ChevronRight/></div>
        </div>
      <Divider/>
      <div style={{padding: 20, paddingTop: 10, paddingBottom: 10, paddingRight: 5}}>
          <div className="absolute mt-2" style={{width: 25, height: 25, borderRadius: '50%', border: '1px solid #662E50'}}></div>
          <div className="fs-12 absolute mt-3" style={{marginLeft: 35, marginTop: 5}}>Notifications</div>
          <div style={{textAlign: 'right'}}>
            <FormControlLabel
              control={<IOSSwitch checked={state.checkedA} onChange={handleChange} name="checkedA" />}
              label=""
            />
          </div>
        </div>
      <Divider/>
      <div style={{padding: 20, paddingTop: 10, paddingBottom: 10, paddingRight: 5}}>
          <div className="absolute mt-2" style={{width: 25, height: 25, borderRadius: '50%', border: '1px solid #662E50'}}></div>
          <div className="fs-12 absolute mt-3" style={{marginLeft: 35, marginTop: 5}}>Biometrics</div>
          <div style={{textAlign: 'right'}}>
            <FormControlLabel
              control={<IOSSwitch checked={state.checkedB} onChange={handleChange} name="checkedB" />}
              label=""
            />
          </div>
        </div>
      <Divider/>
      <div style={{padding: 20}}>
          <div className="absolute" style={{width: 25, height: 25, borderRadius: '50%', border: '1px solid #662E50'}}></div>
          <div className="fs-12 absolute" style={{marginLeft: 35, marginTop: 5}}>Contact Support</div>
          <div style={{textAlign: 'right'}}><ChevronRight/></div>
        </div>
      <Divider/>
      <div style={{padding: 20}} onClick={() => history.push('/in/unlink-account')}>
          <div className="absolute" style={{width: 25, height: 25, borderRadius: '50%', border: '1px solid #662E50'}}></div>
          <div className="fs-12 absolute" style={{marginLeft: 35, marginTop: 5, color: '#EA2F2F'}}>Unlink Account</div>
          <div style={{textAlign: 'right'}}><ChevronRight/></div>
        </div>
      <Divider/>
      <div style={{padding: 20, marginTop: 10}}>
        <Button className="text-capitalize bg-white w-full bold fs-13 text-purple" style={{border: '1px solid #662E50'}}>Log Out</Button>
      </div>
      </div>
    );
  }
  