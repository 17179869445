import { Button, Divider } from "@material-ui/core";
import { ArrowBack, Done } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { createBudget, getBudgets } from "../../services/budgets/budgets.service";

export default function CreateBudget() {
    const { userID, hash } = useSelector(state => state.auth.auth)
    const [budgets, setBudgets] = useState([])
    const history = useHistory()
    const [formData, setFormData] = useState({})

    const getUserBudgets = () => {
        const payload = {
            hash,
            userID: "TR-29149410"
        }
        getBudgets(payload)
        .then(res => res.json())
        .then(response => {
            if (response.success){
                setBudgets(response.data)
                response.data.forEach(item => {
                    const obj = {
                        category: item.category_id,
                        amount: item.amount,
                        start_date: '',
                        end_date: '',
                        user_id: 'TR-29149410',
                        hash

                    }
                    formData[item.category_id] = obj
                })
                setFormData(formData)
            }
        })
        .catch(e => console.error(e))
    }

    const onChange = (event) => {
        const { name, value } = event.target
        setFormData({...formData, [name]: {...formData[name], ['amount']: +value} })
    }

    const saveBudget = () => {
        const payload = {
            hash,
            data: formData
        }
        createBudget(payload)
        .then(res => res.json())
        .then(response => {
            if (response.success){
                history.push('/in/budget')
            }
        })
        .catch(e => console.error(e))
    }

    useEffect(() => {
        getUserBudgets()
    }, [])

  return (
    <div style={{marginBottom: 70}}>
            <div className="" style={{padding: 20}}>
                <ArrowBack className="absolute" style={{marginTop: 10}} onClick={() => history.goBack()}/>
                <div className="text-center mt-3 fs-16 bold">Create Budget</div>
                <div style={{float: 'right', marginTop: -4}}>
                <Button className="bg-purple text-white" onClick={saveBudget} style={{transform: 'translateY(-20px)'}}><Done/></Button>
                {/* <Add className="ml-2" style={{transform: 'translateY(-17px)'}} /> */}
                </div>
            </div>
            <div style={{padding: 20}}>
                <div style={{border: '1px solid #662E50', borderRadius: 8, padding: 20}}>
                    <div className="text-center bold">Total Budget Amount</div>
                    <div className="mt-4 text-purple bold text-center">₦150,000</div>
                </div>
                <div className="text-center fs-14 mt-4">
                Budgets start at the begining of every month or when you recieve your salary
                </div>
            </div>
            <Divider/>
            {
                budgets.length ?
                budgets.map((budget, index) => (
                    <div>
                    <div style={{padding: 20}}>
                    <div className="absolute bold" style={{border: '2px solid #8A4581', borderRadius: '50%', width: 30, height: 30}}></div>
                    <div className="absolute bold mt-2" style={{marginLeft: 50}}>{budget.category}</div>
                    <div>
                        <input type="text" placeholder="₦0,000" onChange={onChange} name={budget.category_id} className="text-center w-full bold" style={{border: 'none', outline: 'none', marginTop: 50, fontSize: 20}}/>
                    </div>
                </div>
                <Divider style={{marginTop: 10}}/>
                </div>
                ))
                : null
            }
    
    </div>
  );
}
