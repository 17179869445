import { Avatar, Button, Divider, FormControl, fade, InputBase, CircularProgress, Grid } from "@material-ui/core";
import { Add, ArrowBack, ChevronRight, Edit, EditOutlined } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useState } from "react";
import { useHistory } from "react-router";

const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      }
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#FCF3FF',
      border: '1px solid #BCBBBB',
      fontSize: 14,
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        boxShadow: `${fade('#4285f4', 0.25)} 0 0 0 0.2rem`,
        borderColor: '#4285f4',
      },
    },
  }))(InputBase);

export default function CreateCustomCategory() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [categoryName, setCategoryName] = useState('')

  const onChange = () => {

  }

  const onSubmit = (e) => {
    e.preventDefault()
  }

    return (
      <div style={{marginBottom: 20}}>
          <div className="" style={{padding: 20}}>
              <ArrowBack className="absolute" style={{marginTop: 10}} onClick={() => history.goBack()}/>
              <div className="text-center mt-3 fs-16 bold">Create Custom Category</div>
          </div>
          <div className="mt-2 text-center fs-12" style={{padding: 20}}>
            <FormControl className="" style={{width: '100%'}}>
              <BootstrapInput defaultValue={categoryName} name="categoryName" id="categoryName" onChange={onChange} autoComplete="off" placeholder="Category Name"/>
            </FormControl>
          </div>
          <div className="fs-12 text-center">Pick an emoji for your category</div>
          <div style={{width: '100%'}}>
            <Grid container spacing={2} style={{margin: 'auto', padding: 20}}>
            {
                [1,2,3,4,5,6,7,8,9,10,11,12].map((item, index) => (
                    <Grid item xs={3} className="">
                        <div style={{width: 50, height: 50, background: '#F5EAF9', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)', borderRadius: '50%'}}></div>
                    </Grid>
                ))
            }
            </Grid>
          </div>

      </div>
    );
  }
  