import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { DialogContent, Divider } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Connect from '@mono.co/connect.js';
import { userAddAccount } from '../../services/dashboard/dashboard.service'

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { userID, hash } = useSelector(state => state.auth.auth)
  const { onClose, selectedValue, open } = props;
  const history = useHistory()

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
    <div className="fs-16 text-purple bold" style={{padding: 20}}>{props.title}</div>
    <Divider/>

    {props.accounts.length ? props.accounts.map(account => (
        <div style={{padding: 20}} onClick={() => history.push({pathname: '/in/transactions', state: account.provider_id})}>
        <div>
            <div className="absolute inline-block">
                <Avatar className="absolute" style={{width: 30, height: 30}}/>
                <div className="inline-block" style={{marginLeft: 35}}>
                    <div className="fs-14">Savings</div>
                    <div className="fs-10 text-gray">{account.institution_type}(...{account.accountNumber.substring(10, 6)})</div>
                </div>
            </div>
            <div className="inline-block float-right text-right">
                <div className="fs-14 text-gray">₦{account.balance.toLocaleString()}</div>
                <div className="fs-10 text-gray">4 seconds ago</div>
            </div>
        </div>
    </div>
    )): ''}

        <Divider/>

        <div style={{padding: 20}}>
            <Button className="bg-white" style={{border: '1px solid #000', borderRadius: 4}} onClick={() => props.connectAccount(props.title.toLowerCase())}>
                <Add className="text-purple"/>
                <span className="text-purple text-capitalize">Connect a {props.title} account</span>
            </Button>
        </div>
        {/* </DialogContent> */}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function DashboardDialog(props) {
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const { crypto, business, personal } = useSelector(state => state.auth.accountSummary)

  return (
    <div>
      <SimpleDialog 
      selectedValue={selectedValue} 
      open={props.open} 
      title={props.title} 
      accounts={props.accounts} 
      onClose={() => props.handleClose(false)} 
      connectAccount={props.connectAccount} />
    </div>
  );
}
