import { useState } from "react";
import {
  Button,
  fade,
  withStyles,
  FormControl,
  InputBase,
  Checkbox,
  CircularProgress,
  InputAdornment,
  IconButton,
  FormControlLabel,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Link, useHistory } from "react-router-dom";
import { signup } from "../../services/authentication/authentication.service";
import { useDispatch } from "react-redux";
import { setAuthDetails } from "../../redux/slices/authentication/authSlice";
import logo from "../../assets/images/logo.svg";
import { countries } from "../../data/countries";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fff",
    border: "0px solid #BCBBBB",
    fontSize: 14,
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      boxShadow: `${fade("#4285f4", 0.25)} 0 0 0 0rem`,
      borderColor: "#4285f4",
    },
  },
}))(InputBase);

export default function Register() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    country: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("submit", formData);
    if (!formData.email || !formData.password) {
      setError("Please enter required fields");
      return;
    }
    delete formData.confirmpassword;
    setLoading(true);
    signup(formData)
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setLoading(false);
          dispatch(
            setAuthDetails({
              userID: response.data.userID,
            })
          );
          history.push("/enter-pin");
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div style={{ padding: 20, marginTop: 50 }}>
      <div className="text-center">
        <img src={logo} />
      </div>
      <h4 className="text-center text-gray">Hello! Create Account</h4>
      <div className="fs-12 text-purple text-center">
        It only takes a minute to start staying on top of your finances
      </div>
      <form onSubmit={onSubmit}>
        <div className="flex">
          <div className="mt-4 w-50">
            <FormControl className="mr-2 input">
              <BootstrapInput
                defaultValue={formData.firstname}
                name="first name"
                id="firstname"
                onChange={onChange}
                autoComplete="off"
                placeholder="Firstname"
              />
            </FormControl>
          </div>
          <div className="mt-4 w-50">
            <FormControl className="ml-2 input">
              <BootstrapInput
                defaultValue={formData.lastname}
                name="last name"
                id="lastname"
                onChange={onChange}
                autoComplete="off"
                placeholder="Lastname"
              />
            </FormControl>
          </div>
        </div>
        <div className="mt-4">
          <FormControl className="input" style={{ width: "100%" }}>
            <BootstrapInput
              defaultValue={formData.email}
              name="email"
              id="email"
              onChange={onChange}
              autoComplete="off"
              placeholder="Email"
            />
          </FormControl>
        </div>
        <div className="mt-4">
          <FormControl className="input" style={{ width: "100%" }}>
            <BootstrapInput
              defaultValue={formData.password}
              name="password"
              id="password"
              type={showPassword ? "text" : "password"}
              onChange={onChange}
              autoComplete="off"
              placeholder="Password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>

        <span className="error" style={{ fontSize: 12, color: "red" }}>
          {error}
        </span>

        <div className="mt-4">
          <FormControl className="input" style={{ width: "100%" }}>
            <select
              style={{ border: "none", outline: "none", padding: "10px 12px", background: '#fff' }}
              id="country"
              name="country"
              onChange={onChange}
              className="text-gray"
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
            >
              <option aria-label="None" value="">
                Select Country
              </option>
              {countries.length &&
                countries.map((country) => (
                  <option value={country.id}>{country.name}</option>
                ))}
            </select>
          </FormControl>
        </div>

        <div className="mt-2">
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name="checkedI"
              />
            }
            label={
              <p className="fs-12">
                By registering, I have read and agreed to the{" "}
                <a
                  href="https://www.notion.so/Terms-of-Use-4c87b91ed2744e1cb114fe18dd1275b4"
                  className="text-purple"
                  style={{ textDecoration: "none" }}
                >
                  Terms of use and Privacy policy.
                </a>
              </p>
            }
          />
        </div>
        <div className="mt-0">
          <Button
            className="text-white w-full text-capitalize bold bg-purple mt-4"
            type="submit"
          >
            {loading ? (
              <CircularProgress
                className="text-white"
                style={{ width: 25, height: 25 }}
              />
            ) : (
              "Sign Up"
            )}
          </Button>
          <div className="text-gray fs-14 text-center mt-4">
            {" "}
            Already have an account?{" "}
            <Link to="/" className="text-decoration-none">
              <span className="text-purple"> Log In</span>
            </Link>{" "}
          </div>
        </div>
      </form>
    </div>
  );
}
