import { useEffect, useState } from "react";
import { Button, Divider, fade, withStyles, FormControl, InputBase, InputLabel, FormControlLabel, Checkbox, FormGroup, Switch, CircularProgress, Grid } from '@material-ui/core';
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { verifyOtp } from "../../services/authentication/authentication.service";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #BCBBBB',
    fontSize: 14,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      boxShadow: `${fade('#4285f4', 0.25)} 0 0 0 0.2rem`,
      borderColor: '#4285f4',
    },
  },
}))(InputBase);


export default function EnterPin() {
  const history = useHistory()
  const location = useLocation
  const userID = useSelector(state => state.auth.auth.userID)
  const email = useSelector(state => state.auth.email)
  console.log(userID, email)
  const [pin, setPin] = useState({
    pin1: '',
    pin2: '',
    pin3: '',
    pin4: ''
  })

  const onSubmit = (e) => {
    e.preventDefault()
    const { pin1, pin2, pin3, pin4} = pin
    const OTP = pin1 + pin2 + pin3 + pin4
    const payload = {
      OTP,
      userID
    }

    verifyOtp(payload)
    .then(res => res.json())
    .then(response => {
      if (response.success){
        history.push(email ? '/reset-password' : '/in/overview')
      }
    })

  }

  const onChange = (e) => {
    setPin({...pin, [e.target.name]: e.target.value})
    if (+e.target.id + 1 < 5){
      document.getElementById(`${+e.target.id + 1}`).focus()
    }
  }

  useEffect(() => {
    document.getElementById('1').focus()
  }, [])

  return (
    <div style={{padding: 20, marginTop: 100}}>
        <h4 className="text-center text-gray">Enter Code</h4>
        <form onSubmit={onSubmit}>
          <div className="mt-4 flex">
                <input type="password" className="code-input pin" id="1" name="pin1" onChange={onChange}/>
                <input type="password" className="code-input pin" id="2" name="pin2" onChange={onChange}/>
                <input type="password" className="code-input pin" id="3" name="pin3" onChange={onChange}/>
                <input type="password" className="code-input pin" id="4" name="pin4" onChange={onChange}/>
          </div>
          <div className="mt-4">
            <Button className="text-white w-full text-capitalize bold bg-purple mt-4" type="submit">Verify</Button>
            <div className="text-gray fs-14 text-center mt-4 bold"> A code has been sent to your email </div>
          </div>
        </form>
    </div>
  );
}
