import axios from "axios";

export const postRequest = (url, data) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    },
    body: JSON.stringify(data)
  });
};

export const authPostRequest = (url, data) => {
  return fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const authPostFormData = (url, data) => {
  for (var value of data.values()) {
    console.log("formdata item ", Object.values(value));
  }
  return axios.post(url, data, {
    headers: {
      // 'content-type': 'multipart/form-data'
    }
  });
};

export const postFormData = (url, data) => {
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    }
  });
};

export const putFormData = (url, data) => {
  return axios.put(url, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    }
  });
};

export const putRequest = (url, data) => {
  return fetch(url, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    },
    body: JSON.stringify(data)
  });
};

export const authPutRequest = (url, data) => {
  return fetch(url, {
    method: "PUT",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify(data)
  });
};

export const deleteRequest = (url) => {
  return fetch(url, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`
    }
  });
};

export const getRequest = (url) => {
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    }
  });
};

export const authGetRequest = (url) => {
  return fetch(url, {
    headers: {
      // Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
};


