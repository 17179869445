import React, { Component } from "react";
import EnterPin from "./views/authentication/EnterPin";
import ForgotPassword from "./views/authentication/ForgotPassword";
import Login from "./views/authentication/Login";
import Register from "./views/authentication/Register";
import ResetPassword from "./views/authentication/ResetPassword";
import DashboardLayout from './layout/DashboardLayout';
const Route = require('react-router-dom').Route

class Routes extends Component {

  render() {
    return (
      <>
      <Route path="/" exact strict component={Login} />
      <Route path="/signup" exact strict component={Register} />
      <Route path="/forgot-password" exact strict component={ForgotPassword} />
      <Route path="/reset-password" exact strict component={ResetPassword} />
      <Route path="/enter-pin" exact strict component={EnterPin} />
      <Route path="/in" render={(props) => <DashboardLayout/>} />
        {/* <Route path="/" exact strict component={App} />
        {/* <Route path="/in" render={(props) => <MainLayout {...props} />} /> */}
      </>
    );
  }
}
export default Routes;