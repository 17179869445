import { Avatar, Button, Divider } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { cancelSubscriptions } from "../../services/subscriptions/subscriptions.service";

export default function CancelSubscription() {
    const history = useHistory()
    const location = useLocation()
    const { userID, hash } = useSelector(state => state.auth.auth)

    const cancelSub = () => {
        const payload = {
            merchant: location.state.merchantId,
            userID,
            hash
          }
        cancelSubscriptions(payload)
        .then(res => res.json())
        .then(response => {
            if (response.success){
                history.push('/in/subscriptions')
            }
        })
    }

    return (
      <div style={{marginBottom: 56}}>
          <div className="" style={{padding: 20}}>
              <ArrowBack className="absolute" style={{marginTop: 10}} onClick={() => history.goBack()}/>
              <div className="text-center mt-3 fs-16 bold">Cancel Subscriptions</div>
          </div>

          <div style={{padding: 20, paddingTop: 0}}>
            <div className="bg-purple rounded" style={{padding: 20, marginTop: 10, borderRadius: 4}}>
                <Avatar className="bg-white absolute"/>
                <div style={{marginLeft: 50}}>
                    <div style={{fontSize: 18}} className="bold text-white text-capitalize">Cancel {location.state.merchant}</div>
                    <div className="text-white fs-12 mt-1">
                    You could save up yo #30000 per year by cancelling your subscription.
                    </div>
                </div>
            </div>
          </div>

            <div style={{padding: 20}}>
                <div style={{padding: 20, border: '1px solid #662E50', borderRadius: 8}}>
                    <div className="bold text-center fs-14 text-purple">Cancel for me.</div>
                    <div style={{}} className="fs-12 text-center mt-4">
                        The first account you link on cashmark is free. we’d be charging you a one-time fee of 500,00 for subsequent accounts linked.
                    </div>
                    <Button
                        className="bg-white text-purple bold text-capitalize fs-14 w-full mt-4"
                        style={{border: '1px solid #662E50'}}
                        onClick={cancelSub}
                    >
                        Cancel for me
                    </Button>
                </div>

                <div className="mt-4" style={{padding: 20, border: '1px solid #662E50', borderRadius: 8}}>
                    <div className="bold text-center fs-14 text-purple">Cancel for myself.</div>
                    <Button
                        className="bg-white text-purple bold text-capitalize fs-14 w-full mt-8"
                        style={{border: '1px solid #662E50'}}
                    >
                        Steps to cancel
                    </Button>
                </div>
            </div>
      </div>
    );
  }
  