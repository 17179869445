import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { DialogContent, Divider } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Connect from '@mono.co/connect.js';
import { userAddAccount } from '../../services/dashboard/dashboard.service'

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});


export default function MenuDialog(props) {
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const { crypto, business, personal } = useSelector(state => state.auth.accountSummary)
  const history = useHistory()

  return (
    <div>
          <Dialog onClose={() => props.handleClose()} aria-labelledby="simple-dialog-title" id="yello" open={props.open} style={{background: 'transparent'}}>
              <Button className="bg-white text-purple text-capitalize bold" style={{width: 300}}>Notifications</Button>
              <Button className="bg-white mt-2 text-purple text-capitalize bold" style={{width: 300}} onClick={() => history.push('/in/accounts')}>Accounts</Button>
              {/* <Button className="bg-white mt-2 text-purple text-capitalize bold" style={{width: 300}} onClick={() => history.push('/in/budgets')}>Create Custom Budget</Button> */}
              <Button className="bg-white mt-2 text-capitalize bold" style={{width: 300, color: 'red'}} onClick={() => props.handleClose()}>Cancel</Button>
        </Dialog>
    </div>
  );
}
