import { useEffect, useState } from "react";
import { Avatar, Button, Divider } from '@material-ui/core';
import { Link, useHistory } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import { ArrowDownward, ExpandMore } from "@material-ui/icons";
import Feedback from "../../components/feedback/Feedback";
import Navbar from "../../components/dashboard/Navbar";
import { getDashboardData, getUserDetails, userAddAccount } from "../../services/dashboard/dashboard.service";
import { useDispatch, useSelector } from "react-redux";
import DashboardDialog from '../../components/dashboard/DashboardDialog'
import { setAccountSummary, setUserDetails } from "../../redux/slices/authentication/authSlice";
import Connect from '@mono.co/connect.js';
import { listUserConnections } from "../../services/accounts/accounts.service";
import Zabo from 'zabo-sdk-js'
import { install } from 'resize-observer';

install()

const data = {
    labels: ['1', '2', '3', '4', '5', '6'],
    datasets: [
      {
        label: '',
        data: [12, 19, 3, 5, 2, 3],
        fill: false,
        backgroundColor: '#E27BF5',
        borderColor: '#E27BF5',
        color: '#E27BF5'
      },
    ],
  };
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const months = {
      '1': 'Jan',
      '2': 'Feb',
      '3': 'Mar',
      '4': 'Apr',
      '5': 'May',
      '6': 'Jun',
      '7': 'Jul',
      '8': 'Aug',
      '9': 'Sept',
      '10': 'Oct',
      '11': 'Nov',
      '12': 'Dec'
  }

  const dataForChart = (isLink, labels, data) => {
    return {
        labels,
        datasets: [
          {
            label: '',
            data,
            fill: false,
            backgroundColor: isLink ? '#E27BF5' : '#22A9A1',
            borderColor: isLink ? '#E27BF5' : '#22A9A1',
            color: isLink ? '#E27BF5' : '#22A9A1'
          },
        ],
      };
  } 

export default function Dashboard() {

    const [isLink, setLink] = useState(true)
    const [data, setData] = useState(null)
    const [chartData, setChartData] = useState([])
    const [open, setOpen] = useState(false)
    const [personal, setPersonal] = useState(0)
    const [business, setBusiness] = useState(0)
    const dispatch = useDispatch()
    const history = useHistory()
    const [crypto, setCrypto] = useState(0)
    const { userID, hash } = useSelector(state => state.auth.auth)
    const { noOfRemainingAccounts } = useSelector(state => state.auth.userDetails)
    const [accounts, setAccounts] = useState([])
    const [filteredAccounts, setFilteredAccounts] = useState([])
    const [title, setTitle] = useState('')
    const [type, setType] = useState(null)

    const handleClickOpen = (title, type) => {
        const data = accounts.filter(account => account.typeProv === title.toUpperCase() || account.type === title.toUpperCase())
        setFilteredAccounts(data)
        setTitle(title)
        setType(type)
        setOpen(true);
    };

    const getMonthValue = (key) => {
        return months[key]
    }

    const handleClose = (value) => {
        setOpen(value);
    };

    const connectAccount = () => {
        let connect;
        const config = {
          key: "live_pk_dJEzA4xJfIBui8cDx74o",
          onSuccess: function (response) {
            createAccount(response.code)
          },
          onClose: function () {
          }
        };
        connect = new Connect(config);
        connect.setup();
        connect.open();
    }

    const handleAccountConnection = (type) => {

        if (noOfRemainingAccounts >= 1){
            setOpen(false);
            if (type !== 'crypto'){
                connectAccount()
            } else {
                connectCryptoAccount()
            }
        } else {
            history.push({pathname: '/in/accounts', state: 'opendrawer'})
        }
    }

    const connectCryptoAccount = async () => {
        const zabo = await Zabo.init({
            clientId: 'yWq8wSucPXWPdBv4SR0un8LQfvShNUYHUVKHVchNiMh07zq8EnyjO3QKMoihSkG2',
            env: 'sandbox'
          })

        zabo.connect().onConnection(response => {
            createAccount(response.id)
          }).onError(error => {
        })
    }

    const createAccount = (code) => {
        const payload = {
            userID,
            hash,
            code,
            type,
            providerToken: 0
        }
  
        userAddAccount(payload)
        .then(res => res.json())
        .then(response => {
            if (response.success){
              history.push('/in/accounts')
            }
        })
        .catch(e => console.error(e))
    }

    const handleButtonChange = (type) => {
        let link = type === 'link' ? true : false
         if (type === 'link'){
          setLink(true)
          document.getElementById('link2').classList.add('selected-btn')
          document.getElementById('acc2').classList.remove('selected-btn')
          buildNetworthData(data, link)
        } else {
          setLink(false)
          document.getElementById('link2').classList.remove('selected-btn')
          document.getElementById('acc2').classList.add('selected-btn')
          buildSpendingData(data, link)
        }
    }

    const getData = () => {
        getDashboardData({
            hash,
            userID
        })
        .then(res => res.json()) 
        .then(response => {
            if (response.success){
                dispatch(setAccountSummary(response.data.placeHolders))
                setPersonal(response.data.placeHolders.personal)
                setBusiness(response.data.placeHolders.business)
                setCrypto(response.data.placeHolders.crypto)
                setData(response.data)
                buildSpendingData(response.data, isLink)
                buildNetworthData(response.data, isLink)
            }
        })
        .catch(e => console.error(e))
    }

    const buildSpendingData = (data, isLink) => {
        let label = []
        let amount = []
        let dat = data ? data.graphSpending : []
        dat.forEach(item => {
            const monthKey = new Date(item.trans_date).getMonth() + 1
            const day  = new Date(item.trans_date).getDay() + 1
            const monthValue = getMonthValue(monthKey)

            label.push(`${monthValue} ${day}`)
            amount.push(item.amount)
        })
        setChartData(dataForChart(isLink, label.reverse(), amount.reverse()))
        
    }

    const buildNetworthData = (data, isLink) => {
        let label = []
        let amount = []
        let dat = data ? data.graphNetworth : []
        dat.forEach(item => {
            const monthKey = new Date(item.change_date).getMonth() + 1
            const monthValue = getMonthValue(monthKey)
            label.push(monthValue)
            amount.push(item.balance)
        })
        setChartData(dataForChart(isLink, label.reverse(), amount.reverse()))
    }

    const getUserData = async () => {
        const userDetails = await getUserDetails({
            hash,
            userID
        })
        const response = await userDetails.json()
        if (response.success){
            dispatch(setUserDetails(response.data))
        }
    }

    const getAccounts = () => {
        const payload = {
            hash,
            userID
        }
      listUserConnections(payload)
      .then(res => res.json())
      .then(response => {
          if (response.success){
              setAccounts(response.data)
          }
      })
    }

    useEffect(() => {
        getData()
        getUserData()
        getAccounts()
    }, [])

  return (
    <div style={{marginBottom: 56}}>
        <DashboardDialog 
        open={open} 
        title={title}
        accounts={filteredAccounts} 
        handleClose={handleClose} 
        connectAccount={handleAccountConnection}/>
        <Navbar/>
    <div style={{padding: 20, paddingTop: 75}}>
        <div style={{margin: 'auto', borderRadius: 10, width: 150, background: '#F5EAF9', textAlign: 'center', marginTop: -50}} className="">
          <Button id="link2" className="tab-btn selected-btn text-capitalize fs-12 bold" style={{}} onClick={() => handleButtonChange('link')}>Networth</Button>
          <Button id="acc2" className="tab-btn text-capitalize fs-12 bold" style={{ color: '#837D7D'}}  onClick={ () => handleButtonChange('acc')}>Spending</Button>
        </div>

        <div className="" style={{marginTop: 20}}>
            <div className="text-purple inline-block">
                <div className="bold fs-16">N250,000</div>
                <div className="fs-10 bold" style={{marginTop: 6}}>N9,000,000 since you joined Cashmark</div>
            </div>
            <div className="inline-block float-right">
                <select className="text-purple bold bg-white" style={{padding: 8, border: '1px solid #662E50', borderRadius: 8}}>
                    <option value="monthly">Monthly</option>
                    <option value="weekly">Weekly</option>
                </select>
            </div>
        </div>

        <div className="mt-4">
            <Line data={chartData} options={options} color='#000' />
        </div>

        <div>
            <div className="panes mt-4" onClick={() => handleClickOpen('Personal', 1)}>
                <div className="text-purple fs-16 inline-block">Personal</div>
                <div className="text-gray fs-15 bold inline-block float-right">₦{personal.toLocaleString()}</div>
            </div>
        </div>

        <div>
            <div className="panes mt-4" onClick={() => handleClickOpen('Business', 1)}>
                <div className="text-purple fs-16 inline-block">Business</div>
                <div className="text-gray fs-15 bold inline-block float-right">
                    {business === 0 ? <ExpandMore className="text-purple"/> : `₦${business}`}
                </div>
            </div>
        </div>

        <div>
            <div className="panes mt-4" onClick={() => handleClickOpen('Crypto', 2)}>
                <div className="text-purple fs-16 inline-block">Crypto Currency</div>
                <div className="text-gray fs-15 bold inline-block float-right">
                {crypto === 0 || crypto === undefined ? <ExpandMore className="text-purple"/> : `${crypto}`}
                </div>
            </div>
        </div>

        <div>
            <div className="panes mt-4">
                <div className="text-light-gray fs-16 inline-block bold">Loans</div>
                <div className="text-light-gray fs-12 text-uppercase bold inline-block float-right">
                    Coming soon
                </div>
            </div>
        </div>

        <div>
            <div className="panes mt-4">
                <div className="text-light-gray fs-16 inline-block bold">Investments</div>
                <div className="text-light-gray fs-12 text-uppercase bold inline-block float-right">
                    Coming soon
                </div>
            </div>
        </div>

        <div>
            <div className="panes mt-4">
                <div className="text-gray fs-12 inline-block bold">Recent Transactions</div>
                <div className="text-light-gray fs-12 bold inline-block float-right">See all</div>

                {
                    data ? data.transactions.map((trns, index) => (
                        <div key={index.toString()}>
                            <div className="mt-4">
                                <div className="inline-block">
                                    <div>
                                        <div className="inline-block">
                                            <Avatar style={{marginTop: -5}}/>
                                        </div>
                                        <div className="inline-block absolute ml-2">
                                            <div className="text-gray bold fs-12">{trns.merchants.length > 17 ? trns.merchants.substring(0, 17) + '...' : trns.merchants}</div>
                                            <div className="text-gray fs-11" style={{lineHeight: 1.7}}>{trns.categories}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="inline-block float-right mt-2">
                                    <div className="text-gray bold fs-12 text-right">-₦{trns.amount.toLocaleString()}</div>
                                    <div className="text-gray fs-11 text-right" style={{lineHeight: 1.7}}>{trns.trans_date}</div>
                                </div>
                                
                            </div>
                            <Divider className="mt-4"/>
                        </div>
                    ))
                    : ''
                }

                <div className="mt-4">
                <div className="text-gray fs-11 inline-block" style={{width: 150}}>You have {data && data.transactions.length} uncategorized transactions</div>
                <div className="text-light-gray fs-12 text-uppercase bold inline-block float-right">
                    <Button className="bg-purple text-white text-capitalize" onClick={() => history.push('/in/transactions')}>Categorize</Button>
                </div>
            </div>
            </div>
        </div>
    </div>
        <Feedback/>
    </div>
  );
}
