import { Avatar, Button, Divider, FormControl, fade, InputBase, CircularProgress } from "@material-ui/core";
import { Add, ArrowBack, ChevronRight, Edit, EditOutlined } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useState } from "react";
import { useHistory } from "react-router";

export default function EditCustomCategory() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])

  const onChange = () => {

  }

  const onSubmit = (e) => {
    e.preventDefault()
  }

    return (
      <div style={{marginBottom: 70}}>
          <div className="" style={{padding: 20}}>
              <ArrowBack className="absolute" style={{marginTop: 10}} onClick={() => history.goBack()}/>
              <div className="text-center mt-3 fs-16 bold">Edit Custom Category</div>
          </div>
          <div className="mt-2 text-center fs-12" style={{padding: 20}}>
            Select a custom category to edit and delete it. You can also create a new one
          </div>
      </div>
    );
  }
  