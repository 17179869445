import { useState } from "react";
import { Button, Divider, fade, withStyles, FormControl, InputBase, InputLabel, FormControlLabel, Checkbox, FormGroup, Switch, CircularProgress } from '@material-ui/core';
import { Link, useHistory } from "react-router-dom";
import { verifyEmail } from "../../services/authentication/authentication.service";
import { useDispatch } from "react-redux";
import { setAuthDetails, setEmail } from "../../redux/slices/authentication/authSlice";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #BCBBBB',
    fontSize: 14,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      boxShadow: `${fade('#4285f4', 0.25)} 0 0 0 0.2rem`,
      borderColor: '#4285f4',
    },
  },
}))(InputBase);


export default function ForgotPassword() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    email: ''
  })

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    verifyEmail(formData)
    .then(res => res.json())
    .then(response => {
      if (response.success){
        dispatch(setAuthDetails({
          userID: response.data.userID
        }))
        dispatch(setEmail(formData.email))
        setLoading(false)
        history.push({pathname: '/enter-pin'})
      } else {
        setLoading(false)
      }
    })
    .catch(e => {
      setLoading(false)
      console.error(e)
    })
  }

  const onChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value })
  }

  return (
    <div style={{padding: 20, marginTop: 100}}>
        <h4 className="text-center text-gray">Forgot Password</h4>
        <form onSubmit={onSubmit}>
          <div className="mt-4">
            <FormControl className="" style={{width: '100%'}}>
              <BootstrapInput defaultValue={formData.email} name="email" id="email" onChange={onChange} autoComplete="off" placeholder="Email"/>
            </FormControl>
          </div>
          <div className="mt-4">
            <Button className="text-white w-full text-capitalize bold bg-purple mt-4" type="submit">
            {loading ? <CircularProgress className="text-white" style={{width: 25, height: 25}}/> : 'Send Reset Code'}
            </Button>
            <div className="text-gray fs-14 text-center mt-4"> Back to? <Link to="/" className="text-decoration-none"><span className="text-purple"> Log In</span></Link> </div>
          </div>
        </form>
    </div>
  );
}
