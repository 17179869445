import { Avatar, Button, Divider } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { listSubscriptions } from "../../services/subscriptions/subscriptions.service";

export default function Subscriptions() {
    const history = useHistory()
    const [open, setOpen] = useState(false);
    const { hash, userID } = useSelector(state => state.auth.auth)
    const [subscriptions, setSubscriptions] = useState([])

    const getSubscriptions = () => {
      const payload = {
        hash,
        userID
      }
      listSubscriptions(payload)
      .then(res => res.json())
      .then(response => {
        if (response.success){
          setSubscriptions(response.data)
        }
      })
    }

    useEffect(() => {
      getSubscriptions()
    }, [])

    return (
      <div style={{marginBottom: 56}}>
          <div className="" style={{padding: 20}}>
              <ArrowBack className="absolute" style={{marginTop: 10}} onClick={() => history.goBack()}/>
              <div className="text-center mt-3 fs-16 bold">Subscriptions</div>
          </div>

          <div style={{padding: 20, paddingTop: 0}}>
            <div className="bg-purple rounded" style={{padding: 20, marginTop: 10, borderRadius: 4}}>
                <div className="text-white fs-12">
                  Add accounts to manage your money and view your transactions all in one place. 
                </div>
            </div>
          </div>


        {subscriptions.length ? 
          subscriptions.map((item, index) => (
            <div key={index.toString()}>
              <div style={{padding: 20}}>
                <div className="inline-block">
                  <Avatar className="absolute"/>
                  <div className="fs-11" style={{marginLeft: 50}}>
                    <div className="">{item.merchants}</div>
                    <div className="mt-1">{item.currency}{item.amount.toLocaleString()}</div>
                    <div className="mt-1">{item.trans_date}</div>
                  </div>
                </div>
                <Button onClick={() => history.push({pathname: '/in/cancel-subscription', state: {
                  merchant: item.merchants,
                  merchantId: item.merchant_id
                }})} className="bg-purple text-capitalize text-white" style={{float: 'right'}}>Cancel</Button>
              </div>
              <Divider/>
            </div>
          )): 

          <div style={{padding: 20, paddingTop: 0}}>
          <div className="bg-purple rounded" style={{padding: 20, marginTop: 10, borderRadius: 4}}>
              <div className="text-white fs-12">
              We couldn’t detect any subscription runing on any of your linked accounts.
              If you dont have a subscription, this could be a good thing.
              </div>
          </div>
          <Button className="text-white text-capitalize bold mt-8 bg-purple w-full">
              Select subscription from transactions
          </Button>
        </div>
        }
      </div>
    );
  }
  