import { Avatar, Button, Divider } from "@material-ui/core";
import { Add, ArrowBack, Close, Search } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getTransactions } from "../../services/transactions/transactions.service";

export default function ListTransactions() {
    const history = useHistory()
    const [isSearch, setSearch] = useState(false);
    const location = useLocation()
    const { userID, hash } = useSelector(state => state.auth.auth)
    const [ transactions, setTransactions ] = useState([])
    const [ data, setData ] = useState([])
    const [totalAmount, setTotalAmount] = useState([])

    const getAllTransactions = (providerID) => {
      const payload = {
        hash,
        userID,
        providerID: location.state,
      }
      getTransactions(payload)
      .then(res => res.json())
      .then(response => {
        if (response.success){
          setTotalAmount(response.data.totalAmount)
          setData(response.data.data)
          setTransactions(response.data.data)
        }
      })
      .catch(e => console.error(e))
    }

    useEffect(() => {
      const providerID = location.state
      getAllTransactions(providerID)
    }, [])


    const onChange = (event) => {
      const query = event.target.value
      if (query){
        const results = data.filter(transaction => transaction.categories.toLowerCase().includes(query.toLowerCase()) )
        setTransactions(results)
      } else {
        setTransactions(data)
      }
    }

    return (
      <div style={{marginBottom: 56}}>
          {!isSearch &&
            <div className="" style={{padding: 20}}>
                <ArrowBack className="absolute" style={{marginTop: 10}} onClick={() => history.goBack()}/>
                <div className="text-center mt-3 fs-16 bold">Transactions</div>
                <div style={{float: 'right', marginTop: -4}}>
                <Search className="" onClick={() => setSearch(true)} style={{transform: 'translateY(-17px)'}} />
                <Add className="ml-2" style={{transform: 'translateY(-17px)'}} />
                </div>
            </div>
          }

        {isSearch &&
            <div className="" style={{padding: 20}}>
                {/* <ArrowBack className="absolute" style={{marginTop: 10}} onClick={() => history.goBack()}/> */}
                <div className="text-center mt-3 fs-16 bold"></div>
                <Close className="" onClick={() => setSearch(false)} style={{marginTop: 10, float: 'right', transform: 'translateY(-17px)'}} />
            </div>
          }

        {!isSearch && 
           <div style={{padding: 20}}>
           <div className="fs-12 text-gray">Current Balance</div>
           <div className="bold text-purple mt-2" style={{fontSize: 24}}>₦{totalAmount.toLocaleString()}</div>
           <select className="bg-white mt-3" style={{borderRadius: 8, border: '1px solid #662E50', padding: 8}}>
               <option>Filter</option>
           </select>
           <select className="bg-white mt-3 ml-4" style={{borderRadius: 8, border: '1px solid #662E50', padding: 8}}>
               <option>Sort</option>
           </select>
         </div>       
        }

        {isSearch &&
            <div style={{padding: 20, marginBottom: 10}}>
                <input type="text" placeholder="Search Categories" onChange={onChange} className="w-full"
                    style={{background: '#EBE8E8', height: 40, borderRadius: 8, border: 'none', padding: '0px 0px 0px 8px'}}
                />
            </div>
        }

          <div style={{paddingTop: 0}} className="w-full" style={{background: '#F2EDED'}}>
            <div className="bold fs-14" style={{color: '#263238', padding: 20, paddingTop: 7, paddingBottom: 7}}>March 2021</div>
          </div>


        {
          transactions.length ? transactions.map(item => (
            <div>
              <div style={{padding: 20}}>
                <div className="inline-block">
                  <Avatar className="absolute"/>
                  <div className="fs-11" style={{marginLeft: 50}}>
                    <div className="text-gray bold">{item.merchants.length > 20 ? item.merchants.substring(0, 20) + '...' : item.merchants}</div>
                    <div className="mt-1 text-gray">{item.categories}</div>
                    <div className="mt-1 text-gray">{item.trans_date}</div>
                  </div>
                </div>
                <div className="text-gray fs-14 bold mt-4" style={{float: 'right'}}>-{ item.currency +item.amount.toLocaleString()}</div>
              </div>
              <Divider/>
            </div>
          )) : null
        }
      </div>
    );
  }
  