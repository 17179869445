import { Button } from "@material-ui/core";
import { Add, ArrowBack } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import AccountsDrawer from "./AccountsDrawer";
import Connect from '@mono.co/connect.js';
import { userAddAccount } from "../../services/dashboard/dashboard.service";
import { listUserConnections } from "../../services/accounts/accounts.service";
import AccountTabs from "./AccountTabs";

export default function Accounts() {
    const history = useHistory()
    const location = useLocation()
    const [open, setOpen] = useState(false);
    const { userID, hash } = useSelector(state => state.auth.auth)
    const { noOfRemainingAccounts } = useSelector(state => state.auth.userDetails)
    const { crypto, business, personal } = useSelector(state => state.auth.accountSummary)
    const [accounts, setAccounts] = useState([])
    const [filteredAccounts, setFilteredAccounts] = useState([])
    const [currentTab, setCurrentTab] = useState('personal')

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const setTabAndData = (currentTab) => {
      setCurrentTab(currentTab)
      const data = accounts.filter(account => account.typeProv === currentTab.toUpperCase() || account.type === currentTab.toUpperCase())
      setFilteredAccounts(data)
    }

    const connectAccount = () => {
        let connect;
        const config = {
          key: "live_pk_dJEzA4xJfIBui8cDx74o",
          onSuccess: function (response) {
            console.log(response);
            createAccount(response.code)
          },
          onClose: function () {
            console.log('user closed the widget.')
          }
        };
        connect = new Connect(config);
        connect.setup();
        connect.open();
    }
  
    const handleAccountConnection = () => {
        if (noOfRemainingAccounts >= 1){
            connectAccount()
        } else {
            toggleDrawer()
        }
    }
  
    const createAccount = (code) => {
      const payload = {
          userID,
          hash,
          code,
          type: 1,
          providerToken: 0
      }
  
      userAddAccount(payload)
      .then(res => res.json())
      .then(response => {
          if (response.success){
            getAccounts()
          }
      })
      .catch(e => console.error(e))
  }

  const getAccounts = () => {
      const payload = {
          hash,
          userID
      }
    listUserConnections(payload)
    .then(res => res.json())
    .then(response => {
        if (response.success){
            setAccounts(response.data)
            const data = response.data.filter(account => account.typeProv === currentTab.toUpperCase() || account.type === currentTab.toUpperCase())
            setFilteredAccounts(data)
        }
    })
  }

  const getTotalAmount = (currentTab = 'personal') => {
    return currentTab === 'personal' ?
                personal || personal === 0 ? `₦${personal.toLocaleString()}` : 'N/A' :
            currentTab === 'business' ?
                business || business === 0 ? `₦${business.toLocaleString()}` : 'N/A' :
            currentTab === 'crypto' ?
                crypto || crypto === 0 ? `₦${crypto.toLocaleString()}` : 'N/A' : 'N/A'
  }

  useEffect(() => {
    if (location.state === 'opendrawer'){
      toggleDrawer()
    }
      getAccounts()
  }, [])

    return (
      <div style={{marginBottom: 56, padding: 20}}>
         <AccountsDrawer toggleDrawer={toggleDrawer} open={open} connectAccount={connectAccount}/>
          <div className="">
              <ArrowBack className="absolute" style={{marginTop: -4}} onClick={() => history.goBack()}/>
              <div className="text-center mt-3 fs-16 bold">Accounts</div>
              <Add className="" style={{marginTop: -4, float: 'right', transform: 'translateY(-20px)'}} onClick={handleAccountConnection}/>
          </div>
        {!accounts.length ?
        <div>
          <div className="bg-purple rounded" style={{padding: 20, marginTop: 30, borderRadius: 4}}>
              <div className="text-white fs-12">
                Add accounts to manage your money and view your transactions all in one place. 
              </div>
          </div>

          <div>
            <Button
                style={{
                    border: '1px solid #662E50',
                    borderRadius: 4,
                    marginTop: 20
                }}
                className="bold text-capitalize w-full text-purple"
                onClick={handleAccountConnection}
            >
                Link your first account
            </Button>
          </div>

          <div style={{width: 250, margin: 'auto'}}>
              <div className="bold fs-14 text-purple" style={{marginTop: 30}}>First bank account linked is free.</div>
              <div style={{marginTop: 10}} className="fs-12">
                The first account you link on  cashmark is free. 
                We’d be charging you a one-time fee of 800,00 for subsequent 
                accounts linked.
              </div>
          </div>
          </div>
          :
          <div style={{marginTop: 30}}>
              <div className="" style={{padding: 20, background: '#F5EAF9', borderRadius: 8}}>
                  <div className="fs-14 text-center">Total Balance</div>
                  <div className="bold text-purple mt-4 text-center">{getTotalAmount(currentTab)}</div>
              </div>

             <div style={{marginTop: 20}}>
                <AccountTabs accounts={filteredAccounts} type="link" currentTab = {(currentTab) => setTabAndData(currentTab)}/>
              </div>
          </div>
          }
      </div>
    );
  }