

export const countries = [
    {
       "id":1,
       "name":"Afghanistan"
    },
    {
       "id":2,
       "name":"Åland Islands"
    },
    {
       "id":3,
       "name":"Albania"
    },
    {
       "id":4,
       "name":"Algeria"
    },
    {
       "id":5,
       "name":"American Samoa"
    },
    {
       "id":6,
       "name":"AndorrA"
    },
    {
       "id":7,
       "name":"Angola"
    },
    {
       "id":8,
       "name":"Anguilla"
    },
    {
       "id":9,
       "name":"Antarctica"
    },
    {
       "id":10,
       "name":"Antigua and Barbuda"
    },
    {
       "id":11,
       "name":"Argentina"
    },
    {
       "id":12,
       "name":"Armenia"
    },
    {
       "id":13,
       "name":"Aruba"
    },
    {
       "id":14,
       "name":"Australia"
    },
    {
       "id":15,
       "name":"Austria"
    },
    {
       "id":16,
       "name":"Azerbaijan"
    },
    {
       "id":17,
       "name":"Bahamas"
    },
    {
       "id":18,
       "name":"Bahrain"
    },
    {
       "id":19,
       "name":"Bangladesh"
    },
    {
       "id":20,
       "name":"Barbados"
    },
    {
       "id":21,
       "name":"Belarus"
    },
    {
       "id":22,
       "name":"Belgium"
    },
    {
       "id":23,
       "name":"Belize"
    },
    {
       "id":24,
       "name":"Benin"
    },
    {
       "id":25,
       "name":"Bermuda"
    },
    {
       "id":26,
       "name":"Bhutan"
    },
    {
       "id":27,
       "name":"Bolivia"
    },
    {
       "id":28,
       "name":"Bosnia and Herzegovina"
    },
    {
       "id":29,
       "name":"Botswana"
    },
    {
       "id":30,
       "name":"Bouvet Island"
    },
    {
       "id":31,
       "name":"Brazil"
    },
    {
       "id":32,
       "name":"British Indian Ocean Territory"
    },
    {
       "id":33,
       "name":"Brunei Darussalam"
    },
    {
       "id":34,
       "name":"Bulgaria"
    },
    {
       "id":35,
       "name":"Burkina Faso"
    },
    {
       "id":36,
       "name":"Burundi"
    },
    {
       "id":37,
       "name":"Cambodia"
    },
    {
       "id":38,
       "name":"Cameroon"
    },
    {
       "id":39,
       "name":"Canada"
    },
    {
       "id":40,
       "name":"Cape Verde"
    },
    {
       "id":41,
       "name":"Cayman Islands"
    },
    {
       "id":42,
       "name":"Central African Republic"
    },
    {
       "id":43,
       "name":"Chad"
    },
    {
       "id":44,
       "name":"Chile"
    },
    {
       "id":45,
       "name":"China"
    },
    {
       "id":46,
       "name":"Christmas Island"
    },
    {
       "id":47,
       "name":"Cocos (Keeling) Islands"
    },
    {
       "id":48,
       "name":"Colombia"
    },
    {
       "id":49,
       "name":"Comoros"
    },
    {
       "id":50,
       "name":"Congo"
    },
    {
       "id":51,
       "name":"Congo, The Democratic Republic of the"
    },
    {
       "id":52,
       "name":"Cook Islands"
    },
    {
       "id":53,
       "name":"Costa Rica"
    },
    {
       "id":54,
       "name":"Cote D'Ivoire"
    },
    {
       "id":55,
       "name":"Croatia"
    },
    {
       "id":56,
       "name":"Cuba"
    },
    {
       "id":57,
       "name":"Cyprus"
    },
    {
       "id":58,
       "name":"Czech Republic"
    },
    {
       "id":59,
       "name":"Denmark"
    },
    {
       "id":60,
       "name":"Djibouti"
    },
    {
       "id":61,
       "name":"Dominica"
    },
    {
       "id":62,
       "name":"Dominican Republic"
    },
    {
       "id":63,
       "name":"Ecuador"
    },
    {
       "id":64,
       "name":"Egypt"
    },
    {
       "id":65,
       "name":"El Salvador"
    },
    {
       "id":66,
       "name":"Equatorial Guinea"
    },
    {
       "id":67,
       "name":"Eritrea"
    },
    {
       "id":68,
       "name":"Estonia"
    },
    {
       "id":69,
       "name":"Ethiopia"
    },
    {
       "id":70,
       "name":"Falkland Islands (Malvinas)"
    },
    {
       "id":71,
       "name":"Faroe Islands"
    },
    {
       "id":72,
       "name":"Fiji"
    },
    {
       "id":73,
       "name":"Finland"
    },
    {
       "id":74,
       "name":"France"
    },
    {
       "id":75,
       "name":"French Guiana"
    },
    {
       "id":76,
       "name":"French Polynesia"
    },
    {
       "id":77,
       "name":"French Southern Territories"
    },
    {
       "id":78,
       "name":"Gabon"
    },
    {
       "id":79,
       "name":"Gambia"
    },
    {
       "id":80,
       "name":"Georgia"
    },
    {
       "id":81,
       "name":"Germany"
    },
    {
       "id":82,
       "name":"Ghana"
    },
    {
       "id":83,
       "name":"Gibraltar"
    },
    {
       "id":84,
       "name":"Greece"
    },
    {
       "id":85,
       "name":"Greenland"
    },
    {
       "id":86,
       "name":"Grenada"
    },
    {
       "id":87,
       "name":"Guadeloupe"
    },
    {
       "id":88,
       "name":"Guam"
    },
    {
       "id":89,
       "name":"Guatemala"
    },
    {
       "id":90,
       "name":"Guernsey"
    },
    {
       "id":91,
       "name":"Guinea"
    },
    {
       "id":92,
       "name":"Guinea-Bissau"
    },
    {
       "id":93,
       "name":"Guyana"
    },
    {
       "id":94,
       "name":"Haiti"
    },
    {
       "id":95,
       "name":"Heard Island and Mcdonald Islands"
    },
    {
       "id":96,
       "name":"Holy See (Vatican City State)"
    },
    {
       "id":97,
       "name":"Honduras"
    },
    {
       "id":98,
       "name":"Hong Kong"
    },
    {
       "id":99,
       "name":"Hungary"
    },
    {
       "id":100,
       "name":"Iceland"
    },
    {
       "id":101,
       "name":"India"
    },
    {
       "id":102,
       "name":"Indonesia"
    },
    {
       "id":103,
       "name":"Iran, Islamic Republic Of"
    },
    {
       "id":104,
       "name":"Iraq"
    },
    {
       "id":105,
       "name":"Ireland"
    },
    {
       "id":106,
       "name":"Isle of Man"
    },
    {
       "id":107,
       "name":"Israel"
    },
    {
       "id":108,
       "name":"Italy"
    },
    {
       "id":109,
       "name":"Jamaica"
    },
    {
       "id":110,
       "name":"Japan"
    },
    {
       "id":111,
       "name":"Jersey"
    },
    {
       "id":112,
       "name":"Jordan"
    },
    {
       "id":113,
       "name":"Kazakhstan"
    },
    {
       "id":114,
       "name":"Kenya"
    },
    {
       "id":115,
       "name":"Kiribati"
    },
    {
       "id":116,
       "name":"Korea, Democratic People's Republic of"
    },
    {
       "id":117,
       "name":"Korea, Republic of"
    },
    {
       "id":118,
       "name":"Kuwait"
    },
    {
       "id":119,
       "name":"Kyrgyzstan"
    },
    {
       "id":120,
       "name":"Lao People's Democratic Republic"
    },
    {
       "id":121,
       "name":"Latvia"
    },
    {
       "id":122,
       "name":"Lebanon"
    },
    {
       "id":123,
       "name":"Lesotho"
    },
    {
       "id":124,
       "name":"Liberia"
    },
    {
       "id":125,
       "name":"Libyan Arab Jamahiriya"
    },
    {
       "id":126,
       "name":"Liechtenstein"
    },
    {
       "id":127,
       "name":"Lithuania"
    },
    {
       "id":128,
       "name":"Luxembourg"
    },
    {
       "id":129,
       "name":"Macao"
    },
    {
       "id":130,
       "name":"Macedonia, The Former Yugoslav Republic of"
    },
    {
       "id":131,
       "name":"Madagascar"
    },
    {
       "id":132,
       "name":"Malawi"
    },
    {
       "id":133,
       "name":"Malaysia"
    },
    {
       "id":134,
       "name":"Maldives"
    },
    {
       "id":135,
       "name":"Mali"
    },
    {
       "id":136,
       "name":"Malta"
    },
    {
       "id":137,
       "name":"Marshall Islands"
    },
    {
       "id":138,
       "name":"Martinique"
    },
    {
       "id":139,
       "name":"Mauritania"
    },
    {
       "id":140,
       "name":"Mauritius"
    },
    {
       "id":141,
       "name":"Mayotte"
    },
    {
       "id":142,
       "name":"Mexico"
    },
    {
       "id":143,
       "name":"Micronesia, Federated States of"
    },
    {
       "id":144,
       "name":"Moldova, Republic of"
    },
    {
       "id":145,
       "name":"Monaco"
    },
    {
       "id":146,
       "name":"Mongolia"
    },
    {
       "id":147,
       "name":"Montserrat"
    },
    {
       "id":148,
       "name":"Morocco"
    },
    {
       "id":149,
       "name":"Mozambique"
    },
    {
       "id":150,
       "name":"Myanmar"
    },
    {
       "id":151,
       "name":"Namibia"
    },
    {
       "id":152,
       "name":"Nauru"
    },
    {
       "id":153,
       "name":"Nepal"
    },
    {
       "id":154,
       "name":"Netherlands"
    },
    {
       "id":155,
       "name":"Netherlands Antilles"
    },
    {
       "id":156,
       "name":"New Caledonia"
    },
    {
       "id":157,
       "name":"New Zealand"
    },
    {
       "id":158,
       "name":"Nicaragua"
    },
    {
       "id":159,
       "name":"Niger"
    },
    {
       "id":160,
       "name":"Nigeria"
    },
    {
       "id":161,
       "name":"Niue"
    },
    {
       "id":162,
       "name":"Norfolk Island"
    },
    {
       "id":163,
       "name":"Northern Mariana Islands"
    },
    {
       "id":164,
       "name":"Norway"
    },
    {
       "id":165,
       "name":"Oman"
    },
    {
       "id":166,
       "name":"Pakistan"
    },
    {
       "id":167,
       "name":"Palau"
    },
    {
       "id":168,
       "name":"Palestinian Territory, Occupied"
    },
    {
       "id":169,
       "name":"Panama"
    },
    {
       "id":170,
       "name":"Papua New Guinea"
    },
    {
       "id":171,
       "name":"Paraguay"
    },
    {
       "id":172,
       "name":"Peru"
    },
    {
       "id":173,
       "name":"Philippines"
    },
    {
       "id":174,
       "name":"Pitcairn"
    },
    {
       "id":175,
       "name":"Poland"
    },
    {
       "id":176,
       "name":"Portugal"
    },
    {
       "id":177,
       "name":"Puerto Rico"
    },
    {
       "id":178,
       "name":"Qatar"
    },
    {
       "id":179,
       "name":"Reunion"
    },
    {
       "id":180,
       "name":"Romania"
    },
    {
       "id":181,
       "name":"Russian Federation"
    },
    {
       "id":182,
       "name":"RWANDA"
    },
    {
       "id":183,
       "name":"Saint Helena"
    },
    {
       "id":184,
       "name":"Saint Kitts and Nevis"
    },
    {
       "id":185,
       "name":"Saint Lucia"
    },
    {
       "id":186,
       "name":"Saint Pierre and Miquelon"
    },
    {
       "id":187,
       "name":"Saint Vincent and the Grenadines"
    },
    {
       "id":188,
       "name":"Samoa"
    },
    {
       "id":189,
       "name":"San Marino"
    },
    {
       "id":190,
       "name":"Sao Tome and Principe"
    },
    {
       "id":191,
       "name":"Saudi Arabia"
    },
    {
       "id":192,
       "name":"Senegal"
    },
    {
       "id":193,
       "name":"Serbia and Montenegro"
    },
    {
       "id":194,
       "name":"Seychelles"
    },
    {
       "id":195,
       "name":"Sierra Leone"
    },
    {
       "id":196,
       "name":"Singapore"
    },
    {
       "id":197,
       "name":"Slovakia"
    },
    {
       "id":198,
       "name":"Slovenia"
    },
    {
       "id":199,
       "name":"Solomon Islands"
    },
    {
       "id":200,
       "name":"Somalia"
    },
    {
       "id":201,
       "name":"South Africa"
    },
    {
       "id":202,
       "name":"South Georgia and the South Sandwich Islands"
    },
    {
       "id":203,
       "name":"Spain"
    },
    {
       "id":204,
       "name":"Sri Lanka"
    },
    {
       "id":205,
       "name":"Sudan"
    },
    {
       "id":206,
       "name":"Suriname"
    },
    {
       "id":207,
       "name":"Svalbard and Jan Mayen"
    },
    {
       "id":208,
       "name":"Swaziland"
    },
    {
       "id":209,
       "name":"Sweden"
    },
    {
       "id":210,
       "name":"Switzerland"
    },
    {
       "id":211,
       "name":"Syrian Arab Republic"
    },
    {
       "id":212,
       "name":"Taiwan, Province of China"
    },
    {
       "id":213,
       "name":"Tajikistan"
    },
    {
       "id":214,
       "name":"Tanzania, United Republic of"
    },
    {
       "id":215,
       "name":"Thailand"
    },
    {
       "id":216,
       "name":"Timor-Leste"
    },
    {
       "id":217,
       "name":"Togo"
    },
    {
       "id":218,
       "name":"Tokelau"
    },
    {
       "id":219,
       "name":"Tonga"
    },
    {
       "id":220,
       "name":"Trinidad and Tobago"
    },
    {
       "id":221,
       "name":"Tunisia"
    },
    {
       "id":222,
       "name":"Turkey"
    },
    {
       "id":223,
       "name":"Turkmenistan"
    },
    {
       "id":224,
       "name":"Turks and Caicos Islands"
    },
    {
       "id":225,
       "name":"Tuvalu"
    },
    {
       "id":226,
       "name":"Uganda"
    },
    {
       "id":227,
       "name":"Ukraine"
    },
    {
       "id":228,
       "name":"United Arab Emirates"
    },
    {
       "id":229,
       "name":"United Kingdom"
    },
    {
       "id":230,
       "name":"United States"
    },
    {
       "id":231,
       "name":"United States Minor Outlying Islands"
    },
    {
       "id":232,
       "name":"Uruguay"
    },
    {
       "id":233,
       "name":"Uzbekistan"
    },
    {
       "id":234,
       "name":"Vanuatu"
    },
    {
       "id":235,
       "name":"Venezuela"
    },
    {
       "id":236,
       "name":"Viet Nam"
    },
    {
       "id":237,
       "name":"Virgin Islands, British"
    },
    {
       "id":238,
       "name":"Virgin Islands, U.S."
    },
    {
       "id":239,
       "name":"Wallis and Futuna"
    },
    {
       "id":240,
       "name":"Western Sahara"
    },
    {
       "id":241,
       "name":"Yemen"
    },
    {
       "id":242,
       "name":"Zambia"
    },
    {
       "id":243,
       "name":"Zimbabwe"
    }
 ]