import { Avatar, Button } from "@material-ui/core";

export default function AccountItem (props) {
    return (
        <div className="panes" style={{minHeight: 40}}>
            <div className="absolute">
                <Avatar className="absolute"/>
                <div className="inline-block fs-14" style={{marginLeft: 50}}>
                    <div>{props.account.institution_type}</div>
                    <div>{props.account.accountNumber}</div>
                </div>
            </div>
            {props.type === 'link' ?
                    <div style={{float: 'right', marginTop: 6}} className="fs-16 text-purple bold">
                        {props.account.balance || props.account.balance === 0 ? `₦${(props.account.balance).toLocaleString('EN')}` : 'N/A'}
                    </div> :
                    <div style={{float: 'right', marginTop: 6}} className="">
                        <Button className="bold bg-purple text-white text-capitalize fs-14">Unlink</Button>
                    </div>
            }
        </div>
    )
}