import { Avatar, Button, Divider, FormControl, fade, InputBase, CircularProgress } from "@material-ui/core";
import { ArrowBack, ChevronRight, Edit, EditOutlined } from "@material-ui/icons";
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useState } from "react";
import { useHistory } from "react-router";

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #BCBBBB',
    fontSize: 14,
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    '&:focus': {
      boxShadow: `${fade('#4285f4', 0.25)} 0 0 0 0.2rem`,
      borderColor: '#4285f4',
    },
  },
}))(InputBase);


export default function EditProfile() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: ''
  })

  const onChange = () => {

  }

  const onSubmit = (e) => {
    e.preventDefault()
  }

    return (
      <div style={{marginBottom: 70}}>
          <div className="" style={{padding: 20}}>
              <ArrowBack className="absolute" style={{marginTop: 10}} onClick={() => history.goBack()}/>
              <div className="text-center mt-3 fs-16 bold">Edit Profile</div>
          </div>
          <form onSubmit={onSubmit} style={{padding: 20}}>
          <div className="mt-4 w-45 inline-block">
            <FormControl className="mr-2">
              <BootstrapInput defaultValue={formData.firstname} name="firstname" id="firstname" onChange={onChange} autoComplete="off" placeholder="Firstname"/>
            </FormControl>
          </div>
          <div className="mt-4 w-45 inline-block">
            <FormControl className="ml-2">
              <BootstrapInput defaultValue={formData.lastname} name="lastname" id="lastname" onChange={onChange} autoComplete="off" placeholder="Lastname"/>
            </FormControl>
          </div>
            <div className="mt-4">
              <FormControl className="" style={{width: '100%'}}>
                <BootstrapInput defaultValue={formData.email} name="email" id="email" onChange={onChange} autoComplete="off" placeholder="Email"/>
              </FormControl>
            </div>
            <div className="mt-4">
              <FormControl className="" style={{width: '100%'}}>
                <BootstrapInput defaultValue={formData.phone} name="phone" id="phone" type="text" onChange={onChange} autoComplete="off" placeholder="Phone"/>
              </FormControl>
            </div>
            <div className="mt-4">
              <Button className="text-white w-full text-capitalize bold bg-purple mt-4" type="submit">
                {loading ? <CircularProgress className="text-white" style={{width: 25, height: 25}}/> : 'Save Changes'}
              </Button>
            </div>
        </form>
      </div>
    );
  }
  