import { createSlice } from '@reduxjs/toolkit'

const authData = localStorage.getItem('auth')
const parsedData = JSON.parse(authData)

const userData = localStorage.getItem('userDetails')
const parsedUserData = JSON.parse(userData)

const accountSummary = localStorage.getItem('accountSummary')
const parsedAccountSummary = JSON.parse(accountSummary)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    value: 0,
    auth: parsedData ? parsedData : null,
    email: null,
    userDetails: parsedUserData ? parsedUserData : {
      email: null,
      firstName: null,
      lastName: null,
      userId: null,
      noOfConnectedAccounts: null,
      noOfRemainingAccounts: null
    },
    accountSummary: parsedAccountSummary ? parsedAccountSummary : {
      crypto: 0,
      business: 0,
      personal: 0
    },
    currComponent: null
  },
  reducers: {

    setComponent: (state, action) => {
      state.currComponent = action.payload
  },

    setAuthDetails: (state, action) => {
        localStorage.setItem('auth', JSON.stringify(action.payload))
        state.auth = action.payload
    },

    setUserDetails: (state, action) => {
      localStorage.setItem('userDetails', JSON.stringify(action.payload))
      state.userDetails = action.payload
  },

    setEmail: (state, action) => {
      state.email = action.payload
  },

  setAccountSummary: (state, action) => {
    localStorage.setItem('accountSummary', JSON.stringify(action.payload))
    state.accountSummary = action.payload
}
  },
})

export const { setAuthDetails, setEmail, setComponent, setUserDetails, setAccountSummary } = authSlice.actions

export default authSlice.reducer