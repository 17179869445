import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import { CircularProgress, Grid } from '@material-ui/core';
import { userBuyAccount } from '../../services/accounts/accounts.service';
import { useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../../services/dashboard/dashboard.service';
import { setUserDetails } from '../../redux/slices/authentication/authSlice';
// import { Browser } from '@capacitor/Browser';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const LinkAccount = (props) => {
  return (
    <div style={{minHeight: 250, padding: 20}}>
      <div className="text-purple bold fs-12 text-center">Take Note...</div>
      <div className="fs-12 text-center" style={{marginTop: 20}}>
        To link another bank account with Cashmark, 
        we’d be charging you a one-time fee of 800 Naira 
        as you link the bank account.
      </div>
      <div className="text-purple bold fs-18 text-center" style={{marginTop: 50}}>₦800.00</div>
      <Button className="bg-purple text-white text-capitalize w-full" style={{marginTop: 20}} onClick={() => props.next()}>Continue to link account</Button>
    </div>
  )
}

const SelectNumberOfAccounts = (props) => {
  const [count, setCount] = useState('1')
  
  const updateCount = (item) => {
    setCount(item)
    props.setCount(item)
  }

  return (
    <div style={{minHeight: 350, padding: 20}}>
      <div className="bold fs-12 text-center" style={{width:150, margin: 'auto', paddingTop: 20}}>How many accounts do you want to link?</div>
      <Grid container spacing={2} style={{marginTop: 20}} className="text-center">
        {['1','2','3','4','5', '6', '7', '8', '9'].map(item => (
          <Grid item xs={3}>
            <div onClick={() => updateCount(item)} className={`bg-white ${count === item ? 'selected' : ''}`} style={{padding: 10, borderRadius: 8, boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', width: 30, margin: 'auto'}}>
              {item}
            </div>
          </Grid>
        ))}
      </Grid>
      <Button className="bg-purple text-white w-full bold" style={{marginTop: 50}} onClick={() => props.next()}>OK</Button>
    </div>
  )
}

const ConfirmAccountAndPayment = (props) => {
  return (
    <div style={{minHeight: 350, padding: 20}}>
      <div className="bold fs-12 text-center" style={{width:150, margin: 'auto', paddingTop: 20}}>Please confirm the information below</div>

      <div className="fs-14 text-gray text-center" style={{marginTop: 30}}>Number of accounts to be linked</div>
      <div className="bold text-center" style={{marginTop: 20}}>{props.count}</div>

      <div className="text-purple bold fs-18 text-center" style={{marginTop: 50}}>
        <span className="fs-12 text-gray" style={{fontWeight: 500}}>For the total of </span> ₦{(800 * props.count).toFixed(2)}
      </div>
      <Button className="bg-purple text-white w-full bold" style={{marginTop: 20}} onClick={() => props.initPayment()}>
        {props.loading ? <CircularProgress className="text-white" style={{width: 25, height: 25}}/> : 'CONFIRM'}
      </Button>
    </div>
  )
}

const InitiatePayment = (props) => {
  // const openCapacitorSite = async () => {
  //   await Browser.open({ url: 'http://capacitorjs.com/' });
  // };

  return (
    <div style={{padding: 20}}>
      <iframe src={props.url} style={{height: 540, width: '100%', border: 'none'}} title="Payment"></iframe>
         <Button className="bg-white text-white w-full bold text-purple" style={{marginTop: 20, marginBottom: 40, border:'1px solid #662E50'}} onClick={() => props.updateUserDetailsAndInitAddAccount()}>
          {props.loading ? <CircularProgress className="text-purple" style={{width: 25, height: 25}}/> : 'DONE'}
        </Button>   
    </div>
  )
}

export default function AccountsDrawer(props) {
  const classes = useStyles();
  const [stage, setStage] = useState(1)
  const [count, setCount] = useState(1)
  const { userID, hash } = useSelector(state => state.auth.auth)
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const [showDone, setDone] = useState(false)
  const [url, setUrl] = useState('')
  const dispatch = useDispatch()

  const next = () => {
    setStage(stage + 1)
  }

  const initPayment = () => {
    const payload = {
      userID,
      hash,
      ref: userID,
      numberOfAccounts: +count
    }
    setLoading(true)
    userBuyAccount(payload)
    .then(res => res.json())
    .then(async response => {
      if (response.success){
        setLoading(false)
        setUrl(response.data.link)
          // await Browser.open({ url: response.data.link });
        setDone(true)
        setStage(4)
      } else {
        setLoading(false)
      }
    })
    .catch(e => {
      setLoading(false)
      console.error(e)
    })
  }

  const updateUserDetailsAndInitAddAccount = async () => {
    setLoading2(true)
    const userDetails = await getUserDetails({
        hash,
        userID
    })
    const response = await userDetails.json()
    if (response.success){
      setLoading2(false)
        dispatch(setUserDetails(response.data))
        const { noOfRemainingAccounts } = response.data
        if (noOfRemainingAccounts){
          props.toggleDrawer()
          props.connectAccount()
        } else {
          setLoading2(false)
          setStage(1)
        }
    }
}


  return (
    <div>
        <React.Fragment>
          <Drawer anchor={'bottom'} open={props.open} onClose={() => props.toggleDrawer()}>
            {stage === 1 && <LinkAccount next={next}/>}
            {stage === 2 && <SelectNumberOfAccounts next={next} setCount={(item) => setCount(item)}/>}
            {stage === 3 && <ConfirmAccountAndPayment next={next}
                              count={count} 
                              initPayment={initPayment} 
                              loading={loading}
                              showDone={showDone}
                              updateUserDetailsAndInitAddAccount={updateUserDetailsAndInitAddAccount}
                              />}
            {stage === 4 && <InitiatePayment 
              url={url}
              updateUserDetailsAndInitAddAccount={updateUserDetailsAndInitAddAccount}
              loading={loading2}
            />}
          </Drawer>
        </React.Fragment>
    </div>
  );
}
